<template>
  <div class="mx-4 mt-6">
    <Toast />
    <div class="w-full fixed top-0 left-0 py-3 px-4 z-5 bg-white shadow-2">
      <!-- Topbar -->
      <div class="flex justify-content-between align-items-center">
        <span class="text-2xl font-bold">採購</span>
        <!-- <Button class="p-button- p-button-purpply" label="查看採購單" @click="displayListDialog = true"></Button> -->
      </div>
      <div class="fixed bottom-8 right-4">
        <!-- footer bar -->
        <div class="relative">
          <Button @click="displayListDialog = true" label="採購單" icon="pi pi-file" iconPos="top"
            class="p-button-purpply" />
          <!-- <span v-if="cart.length > 0" class="absolute right-0 bottom-0 mb-4 mr-2 text-pink-300">●</span> -->
        </div>
      </div>
    </div>

    <!-- 採購單 Dialog -->
    <Dialog position="bottom" header="採購單" v-model:visible="displayListDialog" :dismissableMask="true"
      :breakpoints="{ '960px': '96vw' }" :style="{ width: '30vw' }" :modal="true">
      <div v-if="purchaseList.length == 0">目前沒有加入採購商品</div>
      <div class="my-1 flex justify-content-between align-items-center" v-for="(item, idx) in purchaseList" :key="idx">
        <div>
          <div class="inline-block mr-2 text-lg font-bold">{{ item.name }}</div>
          <div class="inline-block">
            {{ item.quantity }} {{ item.uom }}
          </div>
        </div>
        <div class="flex align-items-center">
          <!-- <Button class="p-button-sm bg-blue-100 border-0 mr-2" icon="pi pi-pencil text-blue-500" /> -->
          <Button class="p-button-sm bg-blue-100 border-0" icon="pi pi-trash text-blue-500"
            @click="removeFromList(idx)" />
        </div>
      </div>
      <div class="mt-4">
        <Button class="p-button-secondary p-button w-full" label="送出" :disabled="purchaseList.length == 0"
          @click="submitList" />
      </div>
    </Dialog>

    <template v-for="product in products" :key="product.product_id">
      <div class="my-4 px-4 py-4 bg-white border-round shadow-1 cursor-pointer" @click="selectProduct(product)">
        <span class="text-lg font-bold">{{ product.name }}</span>
      </div>
    </template>

    <!-- 採購數量 Dialog -->
    <Dialog position="bottom" v-model:header="selectedProduct.name" v-model:visible="displaySelectionDialog"
      :dismissableMask="true" :breakpoints="{ '960px': '96vw' }" :style="{ width: '30vw' }" :modal="true">
      <div class="p-inputgroup mt-2">
        <InputText placeholder="輸入採購量" mode="decimal" v-model="selectedProduct.quantity" />
        <span class="p-inputgroup-addon">{{ selectedProduct.uom }}</span>
      </div>
      <div class="mt-4">
        <Button class="p-button-secondary p-button w-full" label="加入採購單"
          :disabled="selectedProduct.quantity === undefined" @click="addToList" />
      </div>
    </Dialog>
  </div>
</template>

<script>
import axios from "axios"
import { reactive } from "vue"

export default {
  data() {
    return {
      products: [],
      selectedProduct: { name: 'Foo', quantity: 0 },
      purchaseList: reactive([]),
      displaySelectionDialog: false,
      displayListDialog: false,
    }
  },
  methods: {
    selectProduct(prod) {
      this.displaySelectionDialog = true
      this.selectedProduct = { ...prod }
    },
    addToList() {
      this.displaySelectionDialog = false
      this.purchaseList.push(this.selectedProduct)
      this.$toast.add({ severity: 'success', summary: '成功', detail: '品項已加入採購單', life: 3000 });
      this.selectedProduct = { name: 'Foo' }
    },
    removeFromList(idx) {
      this.purchaseList.splice(idx, 1);
    },
    submitList() {
      axios.defaults.withCredentials = true
      axios("/api/purchase_products", {
        method: "POST",
        withCredentials: true,
        params: {
          token: "0000",
        },
        data: {
          purchases: this.purchaseList.map(p => {
            return {
              product_id: p.product_id,
              quantity: parseInt(p.quantity)
            }
          })
        }
      })
        .then((response) => {
          console.log(response)
          this.displayListDialog = false
          this.$toast.add({ severity: 'success', summary: '成功', detail: '採購單已送出', life: 3000 })
          this.purchaseList = reactive([])
        })
        .catch((err) => {
          console.log(err.response)
        });
    }
  },
  created() {
    axios.defaults.withCredentials = true
    axios("/api/get_purchasable_products", {
      method: "GET",
      withCredentials: true,
      params: {
        token: "0000",
      },
    })
      .then((response) => {
        console.log(response);
        this.products = response.data.products
      })
      .catch((err) => {
        console.log(err.response);
      })
  },
};
</script>

<style lang="scss" scoped>
.bottom-8 {
  bottom: 5rem;
}

.right-4 {
  right: 1.55rem;
}
</style>
